import 'bootstrap/dist/css/bootstrap.min.css';
import logo_banner from './Images/login_banner.png';
import QR from './Images/qr.jpg';
import fbicon from './Images/fbicon.png';
import instaicon from './Images/instaicon.png';
import twittericon from './Images/twittericon.png';
import youtubeicon from './Images/youtubeicon.png';
import './style.css';

export default function Response() {
    return (
        <div className='container'>
            <div className='response'>
                <div className="card text-center" style={{ width: '30 rem' }}>
                    <img src={logo_banner} alt="img" />
                    <div className='card-body'>
                        <h1>धन्यवाद !! </h1>
                        <p className='card-title text-center'>
                            <b> तपाईको सूचना आमाकोमाया एपमा प्राप्त भयो।   आमाकोमाया मिड वाईफले तपाईलाई सम्पर्क गर्नु हुनेछ। तपाइको गर्भाव्स्थाको स्वास्थ्य सम्बन्धि कुनै पनि जानकारी लिनको लागि तल दिएको नम्बरमा  सम्पर्क गर्नुहोला। </b>
                        </p><br />
                        <h4><b>सम्पर्क नम्बरहरु : </b> </h4>
                        <a href="tel:9761663394">मोबाइल नम्बर : ९७६१६६३३९४</a> <br/>
                        <a href="tel:4428090p01">फोन नम्बर : ०१-४४२८०९०</a><br/>
                        <a href="tel:1660010046"> टोल फ्री नम्बर : १६६००१००४६</a>

                        <br/>  <br/>

                        <a href="https://www.facebook.com/amakomaya2012"><img src={fbicon} alt="fbicon" style={{ width:'40px', height:'auto',marginRight:'10px'}}/></a>
                        <a href="https://www.instagram.com/amakomaya/"><img src={instaicon} alt="instaicon" style={{ width:'40px', height:'auto',marginRight:'10px' }}/></a>
                        <a href="https://twitter.com/amakomaya"><img src={twittericon} alt="twittericon" style={{ width:'40px', height:'auto',marginRight:'10px' }}/></a>
                        <a href="https://www.youtube.com/amakomaya"><img src={youtubeicon} alt="youtubeicon" style={{ width:'40px', height:'auto' }}/></a>
                       <br/> <br/>

                        <p className='card-text'> <b>थप जानकारीको लागी तल दिएको लिंक क्लिक गरी आमाको माया एप डाउनलोड गर्नुहोला। </b></p>

                        <a href="https://play.google.com/store/apps/details?id=com.amakomaya_version2&hl=en_US&gl=US"  className="btn btn-danger active">एप डाउनलोड गर्नुहोस्</a>


                        <br /> <br/>
                        
                            <img className="QR" src={QR} alt="QR" />
                        <br/> <br/>

                    </div>


                </div>
            </div>
            <iframe className='video' src="https://www.youtube.com/embed/ROpJYfTz0MA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </div>
    );
}