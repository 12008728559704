import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Figure from 'react-bootstrap/Figure';
import logo_banner from './Images/login_banner.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FormExample() {
  const [inputs, setInputs] = useState({
    name: "",
    phone: "",
    age: "",
    address: "",
    lmp_date: "",
    counselling_for:[],
  });

  const [validated, setValidated] = useState(false);
  const [showLmpDate, setShowLmpDate] = useState(false);


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const checked = event.target.checked;
  
    if (name === "name") {
      if (/^[a-zA-Z\s-]{2,}$/.test(value)) {
        event.target.setCustomValidity("");
      } else {
        event.target.setCustomValidity("कृपया  सहि नाम टाइप गर्नुहोस्");
      }
    }
    else if (name === "phone") {
      if (/^[0-9]{10}$/.test(value)) {
        event.target.setCustomValidity("");
      } else {
        event.target.setCustomValidity("कृपया  सहि सम्पर्क नम्बर टाइप गर्नुहोस्");
      }
    } else if (name === "age") {
      if (/^[0-9]{2}$/.test(value)) {
        event.target.setCustomValidity("");
      } else {
        event.target.setCustomValidity(" कृपया सहि उमेर टाइप गर्नुहोस्");
      }
    } else {
      event.target.setCustomValidity("");
    }
  
    let newCounsellingFor = [...inputs.counselling_for];
    if (checked && !newCounsellingFor.includes(value)) {
      newCounsellingFor.push(value);
    } else {
      newCounsellingFor = newCounsellingFor.filter((item) => item !== value);
    }
  
    setShowLmpDate(newCounsellingFor.includes("2"));
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
      counselling_for: newCounsellingFor,
      
    }));
  };
  

  const handleDateChange = (lmp_date) => {
    setInputs((prevState) => ({
      ...prevState,
      lmp_date: lmp_date,
    }));
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      const data = (JSON.stringify(inputs));     
     axios
        .post("https://mnch.mohp.gov.np/api/public", data,)
        .then(response => {
          if(response.status === 200){
            window.location.href = "/Form1/Response";
          }
          else{
            toast.error("Something went wrong , please try again later");
          }
        }).catch(function (error) {
          console.log(error);
        });
    }
    setValidated(true);
  }


  return (
   
      <div className='container'>
      <Form className='form' noValidate validated={validated} onSubmit={handleSubmit}>
      <Figure.Image fluid src={logo_banner} alt="Page Banner Image" />

        <Form.Group className="mb-3">
          <Form.Label htmlFor="name">पुरा नाम थर:</Form.Label><br></br>
          <Form.Control style={{ width: '100%', height: '30px' }} type="name" name="name" placeholder="पुरा नाम थर लेख्नुहोस्" required onChange={handleChange} />
          <Form.Control.Feedback type="invalid">
            कृपया पुरा नाम टाइप गर्नुहोस्
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="phone">सम्पर्क नम्बर:</Form.Label><br></br>
          <Form.Control style={{ width: '100%', height: '30px' }} name="phone" type="text" placeholder="सम्पर्क नम्बर लेख्नुहोस्" required onChange={handleChange} pattern="^(\+\d{1,3}[- ]?)?\d{10}$" />
          <Form.Control.Feedback type="invalid">
            कृपया सम्पर्क नम्बर टाइप गर्नुहोस्
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="age">उमेर:</Form.Label><br></br>
          <Form.Control style={{ width: '100%', height: '30px' }} name="age" type="number" placeholder="उमेर लेख्नुहोस्" required onChange={handleChange} />
          <Form.Control.Feedback type="invalid">
            कृपया उमेर टाइप गर्नुहोस्
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="address">ठेगाना:</Form.Label><br></br>
          <Form.Control style={{ width: '100%', height: '30px' }} name="address" type="text" placeholder="ठेगाना लेख्नुहोस्" required onChange={handleChange} />
          <Form.Control.Feedback type="invalid">
            कृपया ठेगाना टाइप गर्नुहोस्
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="counselling_for">परामर्श:</Form.Label><br />
          <Form.Check
            inline
            label="गर्भधारणको पूर्व तयारी सम्बन्धि परामर्श"
            name="counselling_for"
            type="checkbox"
            value="1"
            onChange={handleChange}
          /><br />
          <Form.Check
            inline
            label="गर्भावस्था सम्बन्धि परामर्श"
            name="counselling_for"
            type="checkbox"
            value="2"
            onChange={handleChange}
          /><br />
          <Form.Check
            inline
            label="प्रसूती सम्बन्धि परामर्श"
            name="counselling_for"
            type="checkbox"
            value="3"
            onChange={handleChange}
          /><br />
          <Form.Check
            inline
            label="नवजात शिशुको स्वास्थ्य सम्बन्धि परामर्श"
            name="counselling_for"
            type="checkbox"
            value="4"
            onChange={handleChange}
          /><br />
          <Form.Check
            inline
            label="परिवार नियोजन सम्बन्धि परामर्श"
            name="counselling_for"
            type="checkbox"
            value="5"
            onChange={handleChange}
          /><br />

          <Form.Check
            inline
            label="नवजात शिशुको खोप तथा पोषण सम्बन्धि परामर्श"
            name="counselling_for"
            type="checkbox"
            value="6"
            onChange={handleChange}
          />
        </Form.Group>

        {showLmpDate && (
          <Form.Group className="mb-3">
            <Form.Label htmlFor="lmp_date">महिनावारी भएको अन्तिम मिति:</Form.Label><br />
            <NepaliDatePicker inputClassName="form-control" name="lmp_date" type="text" onChange={handleDateChange} />
          </Form.Group>
        )}

        <div className="d-grid gap-2">
          <Button variant="danger" size="lg" type='submit'>
            बुझाउनुहोस्
          </Button>
        </div>
    </Form>
    <iframe className="video"src="https://www.youtube.com/embed/ROpJYfTz0MA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
  );
}

export default FormExample;
