import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Form from './Form';
import Response from './Response';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
        <Routes>
            <Route path='/' element={<Form />}></Route>
            <Route path='/Form1/Response' element={<Response />}></Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
